import React, { Fragment } from "react";
import About from "../../components/about";
import BlogSection from "../../components/BlogSection";
import Footer from "../../components/footer";
// import Hero from "../../components/hero";
import Navbar from "../../components/Navbar";
import Newslatter from "../../components/Newslatter/Newslatter";
// import Rooms from "../../components/Rooms/Rooms";
import Scrollbar from "../../components/scrollbar";
import Testimonial from "../../components/Testimonial";
import Logo from "../../images/aloha-logo.webp";
import WhyChoose from "../ServiceSinglePage/WhyChoose";
import PageHelemt from "../pageHelmet";
import MobileFooter from "../../components/footer/mobileFooter";

// const Hero = lazy.(()=>import('../../components/hero'));

const Hero = React.lazy(()=>import('../../components/hero'));
const Rooms = React.lazy(()=>import('../../components/Rooms/Rooms'))


const HomePage = () => {
  return (
    <Fragment>
      <PageHelemt />
      <Navbar topbarBlock={"wpo-header-style-2"} Logo={Logo} />
      <Hero />
      {/* <SearchSection/>  */}
      <Rooms />
      <About />
      <section className="service-single-section section-padding">
        <div className="container">
          <div className="row">
            <div className="wpo-section-title-s2">
              <h2>Our Services</h2>
            </div>
            <div className="service-single-content">
              <WhyChoose />
            </div>
          </div>
        </div>
      </section>
      {/* <WhyChoose /> */}
      {/* <FunFact fClass={'wpo-fun-fact-section-s2 section-padding'}/>  */}
      {/* <Destination/>  */}
      {/* <div className="pt-120">
        <ServiceSection />
      </div> */}
      {/* <PricingSection/>  */}
      <div className="pt-120">
        <Testimonial />
      </div>

      <BlogSection />
      <Newslatter />
      <MobileFooter />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default HomePage;

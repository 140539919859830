import React from "react";
import { Link } from "react-router-dom";

const HeaderTopbar = () => {
  return (
    <div className="topbar">
      <div className="container-fluid">
        <div className="row">
          <div className="col col-lg-7 col-md-8 col-sm-12 col-12">
            <div className="contact-intro">
              <ul>
                <li>
                  <a
                    href="mailto:info@alohaapartment.com"
                    className="text-decoration-none text-white"
                  >
                    <i className="bi bi-envelope"></i> info@alohaapartment.com
                  </a>
                </li>
                <li>
                  <a 
                  href="tel:+91 9717177726"
                  className="text-decoration-none text-white"
                  >
                    <i className="bi bi-telephone-forward-fill"></i> +91
                    9717177726
                  </a>
                </li>
                <li>
                  <i className="bi bi-geo-alt-fill"></i> TAPOVAN, RISHIKESH
                </li>
              </ul>
            </div>
          </div>
          <div className="col col-lg-5 col-md-4 col-sm-12 col-12">
            <div className="contact-info">
              <ul>
                {/* <li className="language">
                                    <select name="language" id="language">
                                        <option value="">English</option>
                                        <option value="">Arabi bic</option>
                                        <option value="">France</option>
                                    </select>
                                </li> */}
                {/* <li><Link to="/"><i className="bi bi-facebook"></i></Link></li>
                                <li><Link to="/"><i className="bi bi-twitter"></i></Link></li>
                                <li><Link to="/"><i className="bi bi-linkedin"></i></Link></li>
                                <li><Link to="/"><i className="bi bi-instagram"></i></Link></li>
                                <li><Link to="/"><i className="bi bi-pinterest"></i></Link></li>
                                <li><Link to="/"><i className="bi bi-youtube"></i></Link></li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTopbar;

import { Helmet } from "react-helmet";


const PageHelemt = ({
    title = "Aloha on the Ganges Apartments - Aloha Apartments in Rishikesh.",
    description = "Aloha on The Ganges Apartment in Rishikesh , is a comfortable stay established on the banks of the river Ganges",
    keyword = "Aloha On the Ganges, Aloha Rishikesh, Aloha on the Ganges Rishikesh Resort, Aloha on the ganges apartment, Aloha apartment rishikesh, Aloha on the ganges by leisure hotel, Aloha Resort Rishikesh, Aloha apartments by the Blissful Ganges, Luxury Resort In Rishikesh"
}) => {

    return (
        <>
            <Helmet>
                <title>
                    {title}
                </title>
                <meta name="description" content={description} />
                <meta name="keyword" content={keyword} />

                <script>{`(function (w, d, s, l, i) {
                    w[l] = w[l] || []; w[l].push({
                        'gtm.start':
                            new Date().getTime(), event: 'gtm.js'
                    }); var f = d.getElementsByTagName(s)[0],
                        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
                })(window, document, 'script', 'dataLayer', 'GTM-NPKHNG6B');`}</script>
            </Helmet>
        </>
    )
};

export default PageHelemt;


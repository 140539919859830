import React, { useState } from "react";
import { connect } from "react-redux";
import MobileMenu from "../MobileMenu";
import { removeFromCart } from "../../store/actions/action";
import { Link } from "react-router-dom";
import HeaderTopbar from "../HeaderTopbar";
import { totalPrice } from "../../utils";
// import shape from "../../images/hotel.png"

const Header = (props) => {
  const [menuActive, setMenuState] = useState(false);
  const [cartActive, setcartState] = useState(false);
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const { carts } = props;

  const [scroll, setScroll] = React.useState(0);

  const handleScroll = () => setScroll(document.documentElement.scrollTop);

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const black = scroll > 80 ? true:false;


  return (
    <header id="header" className={props.topbarBlock}>
      <HeaderTopbar />
      <div className={`wpo-site-header ${props.hclass}`}>
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                <div className="mobail-menu">
                  <MobileMenu />
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-6">
                <div className="navbar-header">
                  <Link
                    onClick={ClickHandler}
                    className="navbar-brand logo"
                    to="/home"
                  >
                    <img 
                    src={props.Logo} 
                    width={180}
                    height={40}
                    alt={"logo"} 
                    loading="lazy"
                     />
                  </Link>
                </div>
              </div>
              <div className="col-lg-9 col-md-1 col-1">
                <div
                  id="navbar"
                  className="collapse navbar-collapse navigation-holder"
                >
                  <button className="menu-close">
                    <i className="ti-close"></i>
                  </button>
                  <ul className="nav navbar-nav mb-2 mb-lg-0">
                    <li className="menu-item-has-children text-decoration-none">
                      <Link to="/" 
                      style={{
                        color:black ? "#000":""
                      }}
                      className="text-decoration-none">
                        Home
                      </Link>
                      {/* <ul className="sub-menu">
                          <li><Link className='text-decoration-none' onClick={ClickHandler} to="/home">Home style 1</Link></li>
                          <li><Link className='text-decoration-none' onClick={ClickHandler} to="/home2">Home style 2</Link></li>
                          <li><Link className='text-decoration-none' onClick={ClickHandler} to="/home3">Home style 3</Link></li>
                        </ul> 
                        */}
                    </li>
                    <li>
                      <Link
                        className="text-decoration-none"
                        style={{
                          color:black ? "#000":""
                        }}
                        onClick={ClickHandler}
                        to="/about"
                      >
                        About
                      </Link>
                    </li>
                    <li className="menu-item-has-children">
                      <Link
                        className="text-decoration-none"
                        onClick={ClickHandler}
                        style={{
                          color:black ? "#000":""
                        }}
                        to="#"
                      >
                        Luxury Aloha Apartments
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link
                            className="text-decoration-none"
                            onClick={ClickHandler}
                            style={{
                              color:black ? "#000":""
                            }}
                            to="/one-bed-room"
                          >
                            One Bedroom Apartments
                          </Link>
                          {/* <ul className="sub-menu">
                            <li>
                              <Link
                                className="text-decoration-none"
                                onClick={ClickHandler}
                                to="/garden-view-single"
                              >
                                Bedroom apartment with garden view
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="text-decoration-none"
                                onClick={ClickHandler}
                                to="/ganga-view-single"
                              >
                                Bedroom apartment with ganga view
                              </Link>
                            </li>
                          </ul> */}
                        </li>
                        <li>
                          <Link
                            className="text-decoration-none"
                            onClick={ClickHandler}
                            style={{
                              color:black ? "#000":""
                            }}
                            to="/two-bed-room"
                          >
                            Two Bedroom Apartments
                          </Link>
                          {/* <ul className="sub-menu">
                            <li>
                              <Link
                                className="text-decoration-none"
                                onClick={ClickHandler}
                                to="/room"
                              >
                                Bedroom luxury Apartment with ganga view
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="text-decoration-none"
                                onClick={ClickHandler}
                                to="/room"
                              >
                                Bedroom Premium Luxury Apartment with ganga View
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="text-decoration-none"
                                onClick={ClickHandler}
                                to="/room"
                              >
                                Bedroom Ultra Luxury Apartment with Ganga View
                              </Link>
                            </li>
                          </ul> */}
                        </li>

                        {/* <li><Link className='text-decoration-none' onClick={ClickHandler} to="/room-single/1">Room single</Link></li> */}
                      </ul>
                    </li>

                    {/* <li className="menu-item-has-children">
                        <Link className='text-decoration-none' onClick={ClickHandler} to="/">Destinition</Link>
                        <ul className="sub-menu">
                          <li><Link className='text-decoration-none' onClick={ClickHandler} to="/destination">Destinition</Link></li>
                          <li><Link className='text-decoration-none' onClick={ClickHandler} to="/destination-single/1">Destinition single</Link></li>
                        </ul>
                      </li> */}

                    {/* <li className="menu-item-has-children">
                        <Link className='text-decoration-none' onClick={ClickHandler} to="/blog">Blog</Link>
                        <ul className="sub-menu">
                          <li><Link className='text-decoration-none' onClick={ClickHandler} to="/blog">Blog right sidebar</Link></li>
                          <li><Link className='text-decoration-none' onClick={ClickHandler} to="/blog-left-sidebar">Blog left sidebar</Link></li>
                          <li><Link className='text-decoration-none' onClick={ClickHandler} to="/blog-fullwidth">Blog fullwidth</Link></li>
                          <li className="menu-item-has-children">
                            <Link className='text-decoration-none' onClick={ClickHandler} to="/">Blog details</Link>
                            <ul className="sub-menu">
                              <li><Link className='text-decoration-none' onClick={ClickHandler} to="/blog-single/1">Blog details right sidebar</Link>
                              </li>
                              <li><Link className='text-decoration-none' onClick={ClickHandler} to="/blog-single-left-sidebar/1">Blog details left
                                sidebar</Link></li>
                              <li><Link className='text-decoration-none' onClick={ClickHandler} to="/blog-single-fullwidth/1">Blog details
                                fullwidth</Link></li>
                            </ul>
                          </li>
                        </ul>
                      </li> */}

                    {/* <li className="menu-item-has-children">
                        <Link className='text-decoration-none' onClick={ClickHandler} to="/">Pages</Link>
                        <ul className="sub-menu">
                          <li><Link  className='text-decoration-none' onClick={ClickHandler} to="/service">Service</Link></li>
                          <li><Link className='text-decoration-none' onClick={ClickHandler} to="/service-s2">Service Style2</Link></li>
                          <li><Link className='text-decoration-none' onClick={ClickHandler} to="/service-single/1">Service Single</Link></li>
                          <li><Link className='text-decoration-none' onClick={ClickHandler} to="/cart">Cart</Link></li>
                          <li><Link className='text-decoration-none' onClick={ClickHandler} to="/checkout">Checkout</Link></li>
                          <li><Link className='text-decoration-none' onClick={ClickHandler} to="/pricing">Pricing</Link></li>
                          <li><Link className='text-decoration-none' onClick={ClickHandler} to="/search-result">Search Result</Link></li>
                          <li><Link className='text-decoration-none' onClick={ClickHandler} to="/404">404</Link></li>
                          <li><Link className='text-decoration-none' onClick={ClickHandler} to="/login">Login</Link></li>
                          <li><Link className='text-decoration-none' onClick={ClickHandler} to="/register">Register</Link></li>
                        </ul>
                      </li> */}

                    <li>
                      <Link
                        className="text-decoration-none"
                        onClick={ClickHandler}
                        style={{
                          color:black ? "#000":""
                        }}
                        to="/gallery"
                      >
                        Gallery
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="text-decoration-none"
                        onClick={ClickHandler}
                        style={{
                          color:black ? "#000":""
                        }}
                        to="/contact"
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-1 col-md-2 col-2">
                <div className="header-right">
                  <div className="header-search-form-wrapper">
                    <div className="cart-search-contact">
                      {/* <button
                        onClick={() => setMenuState(!menuActive)}
                        className="search-toggle-btn"
                      >
                        <i
                          className={`bi ${
                            menuActive ? "bi-x-lg" : "bi bi-search"
                          }`}
                        ></i>
                      </button> */}
                      <div
                        className={`header-search-form ${
                          menuActive ? "header-search-content-toggle" : ""
                        }`}
                      >
                        <form onSubmit={SubmitHandler}>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search here..."
                            />
                            <button type="submit">
                              <i className="bi bi-search"></i>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="mini-cart">
                    {/* <button className="cart-toggle-btn" onClick={() => setcartState(!cartActive)}>
                        {" "}
                        <i className="bi bi-cart"></i>{" "}
                        <span className="cart-count">{carts.length}</span>
                      </button> */}
                    <div
                      className={`mini-cart-content ${
                        cartActive ? "mini-cart-content-toggle" : ""
                      }`}
                    >
                      <button
                        className="mini-cart-close"
                        onClick={() => setcartState(!cartActive)}
                      >
                        <i className="ti-close"></i>
                      </button>
                      <div className="mini-cart-items">
                        {carts &&
                          carts.length > 0 &&
                          carts.map((catItem, crt) => (
                            <div className="mini-cart-item clearfix" key={crt}>
                              <div className="mini-cart-item-image">
                                <span>
                                  <img src={catItem.proImg} alt="icon" />
                                </span>
                              </div>
                              <div className="mini-cart-item-des">
                                <p>{catItem.title} </p>
                                <span className="mini-cart-item-price">
                                  ${catItem.price} x {catItem.qty}
                                </span>
                                <span className="mini-cart-item-quantity">
                                  <button
                                    onClick={() =>
                                      props.removeFromCart(catItem.id)
                                    }
                                    className="btn btn-sm btn-danger"
                                  >
                                    <i className="ti-close"></i>
                                  </button>{" "}
                                </span>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className="mini-cart-action clearfix">
                        <span className="mini-checkout-price">
                          Subtotal: <span> ${totalPrice(carts)}</span>
                        </span>
                        <div className="mini-btn">
                          <Link
                            onClick={ClickHandler}
                            to="/checkout"
                            className="view-cart-btn s1 text-decoration-none"
                          >
                            Checkout
                          </Link>
                          <Link
                            onClick={ClickHandler}
                            to="/cart"
                            className="view-cart-btn text-decoration-none"
                          >
                            View Cart
                          </Link>
                        </div>
                      </div>
                      <div className="visible-icon">
                        <img src={"icon"} alt="icon" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
  };
};
export default connect(mapStateToProps, { removeFromCart })(Header);

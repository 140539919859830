import React, { Suspense, lazy, startTransition } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from '../HomePage';
import LoadingSpinner from '../ErrorPage/LoadingSpinner';
// import ThankYouPage from '../ThankYouPage/ThankYouPage';

const ThankYouPage = React.lazy(()=>import('../ThankYouPage/ThankYouPage'));
const EnquiryPage = React.lazy(()=>import('../EnquiryPage/EnquiryPage'));

const Homepage = lazy(() => import('../HomePage'));
const Homepage2 = lazy(() => import('../HomePage2'));
const HomePage3 = lazy(() => import('../HomePage3'));
const AboutPage = lazy(() => import('../AboutPage/AboutPage'));
const CartPage = lazy(() => import('../CartPage'));
const CheckoutPage = lazy(() => import('../CheckoutPage'));
const DestinationPage = lazy(() => import('../DestinationPage/DestinationPage'));
const DestinationSinglePage = lazy(() => import('../DestinationSinglePage/DestinationSinglePage'));
const OrderRecived = lazy(() => import('../OrderRecived'));
const RoomPage = lazy(() => import('../RoomPage'));
const RoomSinglePage = lazy(() => import('../RoomSinglePage'));
const SearchResults = lazy(() => import('../SearchResults/SearchResults'));
const ServicePage = lazy(() => import('../ServicePage/ServicePage'));
const ServicePageS2 = lazy(() => import('../ServicePageS2/ServicePageS2'));
const ServiceSinglePage = lazy(() => import('../ServiceSinglePage/ServiceSinglePage'));
const BlogPage = lazy(() => import('../BlogPage'));
const BlogPageLeft = lazy(() => import('../BlogPageLeft'));
const BlogPageFullwidth = lazy(() => import('../BlogPageFullwidth'));
const BlogDetails = lazy(() => import('../BlogDetails'));
const BlogDetailsFull = lazy(() => import('../BlogDetailsFull'));
const BlogDetailsLeftSiide = lazy(() => import('../BlogDetailsLeftSiide'));
const LoginPage = lazy(() => import('../LoginPage'));
const SignUpPage = lazy(() => import('../SignUpPage'));
const ForgotPassword = lazy(() => import('../ForgotPassword'));
const PricingPage = lazy(() => import('../PricingPage/PricingPage'));
const ErrorPage = lazy(() => import('../ErrorPage/ErrorPage'));
const ContactPage = lazy(() => import('../ContactPage/ContactPage'));
const GalleryPage = lazy(() => import('../Gallery/GalleryPage'));
const OneGardenView = lazy(() => import('../RoomSinglePage/one-garden-view'));
const OneGangaView = lazy(() => import('../RoomSinglePage/one-ganga-view'));
const OneBedRoomPage = lazy(() => import('../RoomPage/one-bed-rooms'));
const TwoBedRoomPage = lazy(() => import('../RoomPage/two-bed-rooms'));
// const LoadingSpinner = lazy(() => import('../ErrorPage/LoadingSpinner'));

const AllRoute = () => {

  return (
    <div className="App">
      <Router
      // basename='aloha-beta'
      >
        <Suspense
          fallback={<LoadingSpinner />}
        >
          <Routes>
            <Route
              path="/"
              element={
                <HomePage />
              }
            />
            {/* <Route path="/home" element={<Homepage />} /> */}
            <Route path='home' element={<Homepage />} />
            <Route path='loading' element={<LoadingSpinner />} />
            <Route path='home2' element={<Homepage2 />} />
            <Route path='home3' element={<HomePage3 />} />
            <Route path='about' element={<AboutPage />} />
            <Route path='gallery' element={<GalleryPage />} />
            <Route path='search-result' element={<SearchResults />} />
            <Route path='garden-view-single' element={<OneGardenView />} />
            <Route path='ganga-view-single' element={<OneGangaView />} />
            <Route path='room-single/:id' element={<RoomSinglePage />} />
            <Route path='cart' element={<CartPage />} />
            <Route path='checkout' element={<CheckoutPage />} />
            <Route path='order_received' element={<OrderRecived />} />
            <Route path='room' element={<RoomPage />} />
            <Route path='one-bed-room' element={<OneBedRoomPage />} />
            <Route path='two-bed-room' element={<TwoBedRoomPage />} />
            <Route path='destination' element={<DestinationPage />} />
            <Route path='destination-single/:id' element={<DestinationSinglePage />} />
            <Route path='service' element={<ServicePage />} />
            <Route path='service-s2' element={<ServicePageS2 />} />
            <Route path='service-single/:id' element={<ServiceSinglePage />} />
            <Route path='pricing' element={<PricingPage />} />
            <Route path='404' element={<ErrorPage />} />
          
            <Route path='contact' element={<ContactPage />} />
            <Route path='thank-you' element={<ThankYouPage />} />
            <Route path='enquiry' element={<EnquiryPage />} />
           
            <Route path='blog-single/:id' element={<BlogDetails />} />
            <Route path='blog-single-left-sidebar/:id' element={<BlogDetailsLeftSiide />} />
            <Route path='blog-single-fullwidth/:id' element={<BlogDetailsFull />} />
            <Route path='blog' element={<BlogPage />} />
            <Route path='blog-left-sidebar' element={<BlogPageLeft />} />
            <Route path='blog-fullwidth' element={<BlogPageFullwidth />} />
            <Route path='login' element={<LoginPage />} />
            <Route path='register' element={<SignUpPage />} />
            <Route path='forgot-password' element={<ForgotPassword />} />
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default AllRoute;

